<template>
  <component :is="componentResult" :name="name" />
</template>

<script>
export default {
  name: "CustomView",
  props: {
    name: {
      type: String,
      required: true
    }
  },
  computed: {
    componentResult() {
      if (this.$utils.isUUID(this.name)) {
        return () => import(`@/views/public/PublicDashboard.vue`);
      } else {
        return () => import(`@/views/private/CustomPage.vue`);
      }
    }
  }
};
</script>

<style scoped></style>
